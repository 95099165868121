<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        
        <!-- Table Container Card -->
                    <b-card>
          
                    <b-form>                  
                <b-row>
              <b-col md="6">
            <b-form-group label="Client">
                  <v-select 
                    v-model="filter_name"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="users"
                    :options="users"
                    placeholder="Select Client"
                    @input="filterTable"
            />
          </b-form-group>   
                </b-col>
               <b-col md="6">
            <b-form-group label="Order Status">
                  <v-select 
                    v-model="selected_order_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="filter_order_status"
                    :options="filter_order_status"
                    placeholder="Select Order Status"
                    @input="filterTable"
            />
          </b-form-group>   
                </b-col>
              <b-col md="3">
            <b-form-group label="Start of Completion Date">
                      <b-form-datepicker
                  
                        v-model="start"
                        
                        :date-format-options="{ year: 'numeric', month: 'short',day: 'numeric' }"
                        locale="en" @input="filterTable" :max="end"
                      />
          </b-form-group>   
                </b-col>
            <b-col md="3">
            <b-form-group label="End of Completion Date">
                      <b-form-datepicker
                  
                        v-model="end"
                        
                        :date-format-options="{ year: 'numeric', month: 'short',day: 'numeric' }"
                        locale="en" @input="filterTable" :min="disabledDates()"
                      />
          </b-form-group>   
                </b-col>
                </b-row>

              <b-row>
              <b-col md="12" class="d-flex align-items-center justify-content-center justify-content-sm-center">

        <b-button variant="warning" @click="resetFilter" v-if="start != '' || end != '' || selected_order_status != null || filter_name != null">
            <span>Reset Filter</span>
        </b-button>
              </b-col>
              </b-row>
          
            </b-form>

                </b-card>

        <!-- Table Container Card -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select 
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search"
                                @input="filterTable"
                            />
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative smallTable"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
            >
                <template #cell(order_number)="items">
                    <p class="">{{ items.item.order_number | capitalize}}</p>
                </template>

                <template #cell(created_by)="items">
                    <p class="">{{ items.item.created_by }}</p>
                </template>

                <template #cell(created_date)="items">
                    <p class="">{{ items.item.created_date}}</p>                 
                </template>

                <template #cell(completion_date)="items">
                    <p class="">{{ items.item.completion_date}}</p>                 
                </template>

                <template #cell(order_status)="items">
            <b-badge
              pill
              :variant="variantColor(items.item.order_status)"
              class="text-capitalize"
            >
              {{ items.item.order_status}}
            </b-badge>             
                </template>

                <template #cell(actions)="items">

                    <div>
                         <span>
            <b-dropdown
              variant="link"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="openModal('View', items.item)">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span>View Order</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openModal('Edit', items.item)">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span>Edit Order</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteOrder(items.item._id, items.item.order_number)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete Order</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
                    </div>

                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
            <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="4" class="d-flex align-items-center justify-content-center justify-content-sm-center">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

         <b-col cols="12" sm="4" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    </b-col>

                </b-row>
            </div>
        </b-card>
                    <!-- View Order Modal -->
        <b-modal 
            id="view-order" centered ref="view-order" header-bg-variant="warning" 
            @hidden="ResetData"
            :title="title"
            no-close-on-backdrop
            hide-footer
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>
               <b-col md="12">
                        <b-form-group label="Created By">
                    <b-form-input type="text" v-model="form.created_by" disabled/>
                </b-form-group>
               </b-col>

                <b-col md="12">
                        <b-form-group label="Delivery Date">
                    <b-form-datepicker v-model="form.delivery_date" locale="en" disabled></b-form-datepicker>
                </b-form-group>
               </b-col>

                <b-col md="12">
                        <b-form-group label="Delivery Timeslot">
                           <b-form-input disabled placeholder="" type="text" v-model="form.delivery_timeslot" autocomplete="off"/>
                </b-form-group>
               </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Completion Date">              
                    <b-form-datepicker v-model="form.completion_date" locale="en" disabled></b-form-datepicker>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Order Status">
                           <b-form-input disabled placeholder="" type="text" v-model="form.order_status" autocomplete="off"/>
                        </b-form-group>
                    </b-col>

                      <b-col md="12">
                        <b-form-group label="Items">
                          <div v-for="i in form.items" :key="i._id">
                          <label style="font-size:18px; color:black">{{i.item_name}} [{{i.product_code}}] x {{i.selected_quantity}}</label>
                          </div>
                        </b-form-group>
                    </b-col>

                </b-row>      
            </b-form>

        </b-modal>
                    <!-- Edit Order Modal -->
        <b-modal 
            id="edit-order" centered ref="edit-order" ok-only header-bg-variant="warning" 
            ok-variant="warning" footer-class="justify-content-center"
            ok-title="Submit" 
            @ok="updateForm(id)"
            :title="title"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>
               <b-col md="12">
                        <b-form-group label="Created By">
                    <b-form-input type="text" v-model="edit_form.created_by" disabled/>
                </b-form-group>
               </b-col>

                <b-col md="12">
                        <b-form-group label="Delivery Date">
                    <b-form-datepicker v-model="edit_form.delivery_date"></b-form-datepicker>
                </b-form-group>
               </b-col>

              <b-col md="12">
                        <b-form-group label="Delivery Timeslot">
                <b-form-select v-model="selected_timeslot">
                     <b-form-select-option :value="i" v-for="i in timeslot" :key="i._id">{{i}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
               </b-col>
                    
                    <b-col md="12">
                        <b-form-group label="Completion Date">              
                    <b-form-datepicker v-model="edit_form.completion_date" :max="max" :disabled='disableCompletionDate'></b-form-datepicker>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Order Status">
                             <b-form-select v-model="edit_form.order_status" @change="checkIfCompleted()">
                    
                          <b-form-select-option :value="type.name" v-for="type in order_status" :key="type._id">{{type.name}}</b-form-select-option>
                             </b-form-select>
                        </b-form-group>
                    </b-col>

                      <b-col md="12">
                        <b-form-group label="Items">
                          <div v-for="i in edit_form.items" :key="i._id">
                          <label style="font-size:18px; color:black">{{i.item_name}} [{{i.product_code}}] x {{i.selected_quantity}}</label>
                          </div>
                        </b-form-group>
                    </b-col>

                </b-row>      
            </b-form>

        </b-modal>
        <!-- Modal to display Image -->
         <b-modal 
            id="image" centered ref="image" header-bg-variant="warning" 
            :title="title"
            no-close-on-backdrop
            hide-footer
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-img :src="this.modalImage" fluid>
                        </b-img>
                    </b-col>

                </b-row>      
            </b-form>

        </b-modal>
    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Bus from "../../../event-bus";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from "moment-timezone";
import axios from 'axios';

export default {
    mixins: [togglePasswordVisibility],
    computed: {
    },
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, VueGoogleAutocomplete, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, moment, BImg
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth())
      minDate.setDate(now.getDate() + 1)
      const defaultValue = moment(minDate).tz('Asia/Singapore').format('YYYY-MM-DD');
      // 15th in two months
      const maxDate = new Date(today)
      //maxDate.setMonth(maxDate.getMonth() + 2)
      //maxDate.setDate(15)

      return {
        value: defaultValue,
        returnDate: null,
        returnDateOld: null,
        min: minDate,
        max: maxDate,
        file1: null,
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,

            tableColumns: [
                { key: 'actions', label: 'Actions', sortable: false , thStyle:  {width: '10%'}}, 
                { key: 'order_number', label: 'Order Number', sortable: true , thStyle:  {width: '20%'}},  
                { key: 'order_status', label: 'Order Status', sortable: true , thStyle:  {width: '10%'}},
                { key: 'created_by', label: 'Created By', sortable: true , thStyle:  {width: '20%'}},
                { key: 'delivery_date', label: 'Delivery Date', sortable: true , thStyle:  {width: '10%'}},
                { key: 'delivery_timeslot', label: 'Delivery Timeslot', sortable: false , thStyle:  {width: '10%'}}, 
                { key: 'completion_date', label: 'Completion Date', sortable: true , thStyle:  {width: '20%'}},
            ],
            // b-table and pagination options
            items           : [],
            isSortDirDesc   : true,
            perPageOptions  : [20, 30, 50, 100],
            perPage         : 20,
            sortBy          : 'id',
            totalRecords    : 0,
            currentPage     : 1,
            searchQuery     : '',
            from            : null,
            to              : null, 
            title: null,
             // Company vars
                form : {
                    order_number: '',
                    created_by:'',
                    delivery_date: '',
                    delivery_timeslot: '',
                    completion_date : '',
                    order_status : '',
                    items: '',
                },
                edit_form : {
                  order_number: '',
                  created_by: '',
                  created_date: '',
                  completion_date: '',
                  order_status: '',
                  items: '',
                },
            order_type: [],
            filter_deployment_date: [],
            start_deployment_date: [],
            end_deployment_date: [],
            order_status: [{name: 'Delivery Completed', status: 'Delivery Completed'}, {name: 'Return Order', status: 'Return Order'}],
            filter_order_status: [],
            selected_order_status: null,
            timeslot: [],
            selected_timeslot: null,
            id: '',
            no_email_change: false,
            start:'',
            end:'',
            modalImage:'',
            doc_icon: require('@/assets/images/doc.png'),
            disableCompletionDate: false,
            users: [],
            filter_name: null
        }
    },

    methods : {
           dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        account_type  : this.$store.getters.currentUser.account_type,
                        site  : this.$store.getters.currentUser.site,
                        filter_order_status : this.selected_order_status,
                        start : this.start,
                        end   : this.end,
                        filter_company_name : this.form.filter_company_name,
                        filter_name : this.filter_name
                    },
                    api: '/api/order-database-records'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },
        openModal(id, item){

                if (id === "View")
                {

                    this.title = "View Order " + item.order_number

                    if (item != null)

                    this.form.delivery_date = moment(item.delivery_date, "DD-MM-YYYY").format('YYYY-MM-DD')
                    this.form.delivery_timeslot = item.delivery_timeslot;
                    this.form.completion_date = moment(item.completion_date, "YYYY-MM-DD").format('YYYY-MM-DD');
                    this.form.created_by = item.created_by;
                    this.form.order_status = item.order_status; 
                    this.form.items = item.items;

                    this.id = item._id;

                    this.$refs['view-order'].show();
                }
                else if (id === "Edit")
                {

                    if (this.error_message == null || this.error_message == "") //Prevent resetting of data if there is error

                    {

                    this.ResetEditData();

                    }

                    if (item != null)

                    {

                    this.title = "Edit Order " + item.order_number
                    if (item.delivery_date != '')
                    {
                      this.edit_form.delivery_date = moment(item.delivery_date, "DD-MM-YYYY").format('YYYY-MM-DD')
                    }
                    this.selected_timeslot = item.delivery_timeslot;
                    this.edit_form.completion_date = moment(item.completion_date, "YYYY-MM-DD").format('YYYY-MM-DD');
                    this.edit_form.created_by = item.created_by;
                    this.edit_form.order_status = item.order_status;
                    this.edit_form.items = item.items;

                    if (this.edit_form.order_status != 'Completed')
                    {
                      this.disableCompletionDate = true;
                    }

                    this.id = item._id;

                    }

                    this.$refs['edit-order'].show();
                }
                else if (id === "Image")
                {
                  this.title = 'Image'

                  this.modalImage = item;             

                    this.$refs['image'].show();
                }
                else {
                 this.title =  'Create New Order';

                if(Object.values(this.form).every(x => x === null || x === '')) //Remove error message if user has not inputted any data in Create function
                {
                    this.error_message = '';
                }

                 if (this.error_message == null || this.error_message == "") //Prevent resetting of data if there is error

                {

                this.ResetData();

                }

                    if (this.form.waste_type.substring(0, 6) == "Others")
                    {
                        this.form.other_waste = this.form.waste_type.substring(9)
                        this.form.waste_type = "Others";
                    }
                    else 
                    {
                         this.form.waste_type = this.form.waste_type;
                    }

                this.$refs['create-order'].show();
                }
        },
        filterTable(){
                this.$refs.refUserListTable.refresh();
        },
    getOrderStatusData(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/get-order-status'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                this.order_status = data;
                
                if (data.length > 0) {

                  data.forEach(i => {
                  this.filter_order_status.push(i.status)
                })
                }

                return this.order_status;
            }
        });
    },
    submitForm(){

        if (this.form.other_waste != "" && this.form.other_waste != null)
        {
            this.form.waste_type = "Others - " + this.form.other_waste;
        }

      return this.$store.dispatch(POST_API, {
        data:{
            company_name : this.$store.getters.currentUser.company_name,
            created_by : this.$store.getters.currentUser.name,
            email: this.$store.getters.currentUser.user_email,
            created_date: moment().tz('Asia/Singapore').format('YYYY-MM-DD HH:mm'),
            deployment_date : this.value,
            return_date: this.returnDate,
            location_address : this.form.location_address,
            addressline2: this.form.addressline2,
            order_type : this.form.order_type,
            waste_type : this.form.waste_type,
            quantity: this.form.quantity,
            assigned_bin : '',
            remarks: this.form.remarks,
            images: this.form.images,
            order_status: 'New',
            account_type: this.$store.getters.currentUser.account_type
        },
        api : '/api/submit-order'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.errorAlertCF()
              this.openModal();
          } else {
              this.showDismissibleAlert = false;

              //var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                this.ResetData();

                localStorage.setItem('routeCFCheck','no');

                this.error_message = "";

                this.$refs.refUserListTable.refresh();

              });
          }
      });
          },
    updateForm(id){
            
      return this.$store.dispatch(POST_API, {
        data:{
            id: id,
            delivery_date: moment(this.edit_form.delivery_date).tz('Asia/Singapore').format('DD-MM-YYYY'),
            delivery_timeslot: this.selected_timeslot,
            completion_date: this.edit_form.completion_date != '' ? moment(this.edit_form.completion_date).tz('Asia/Singapore').format('YYYY-MM-DD') : '',
            order_status: this.edit_form.order_status,
            account_type: this.$store.getters.currentUser.account_type,
        },
        api : '/api/update-order'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;

              this.errorAlertCF();
              this.openModal('Edit');

          } else {
              this.showDismissibleAlert = false;

              //var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                this.ResetEditData();

                this.error_message = "";

                this.$refs.refUserListTable.refresh();

              });
          }
      });
          },
    deleteOrder(id, order_number){
      var msg = 'Are you sure you want to delete the order ' + order_number + ' ? ';
      var message='Order Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          order_status:'Deleted'
                        },
                    api:"/api/delete-order",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    checkIfCompleted(){
      if (this.edit_form.order_status != 'Completed')
      {
        this.edit_form.completion_date = '';
        this.disableCompletionDate = true;
      }
      else
      {
        this.disableCompletionDate = false;
      }
    },
    allCompanies(){
      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/all-companies'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.companies = [];

                if (data.length > 0) {

                  data.forEach(item => {
                    this.companies.push(item.company_name);
                  })
                }

                return data;
            }
        });
    },
    getOrderTypeData(){

      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/get-order-type'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.order_type = data;

                return this.order_type;
            }
        });
    },
    getTimeslotData(){

      return this.$store.dispatch(POST_API, {
           data:{
            site: this.$store.getters.currentUser.site
           },
           api: '/api/get-timeslot'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                data.forEach(i => {
                  this.timeslot.push(i.name)
                })

                return this.timeslot;
            }
        });
    },   
    allUsers(){
      return this.$store.dispatch(POST_API, {
           data:{
            site  : this.$store.getters.currentUser.site,
           },
           api: '/api/all-users'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.users = [];

                if (data.length > 0) {

                  data.forEach(item => {
                    this.users.push(item.name);
                  })
                }

                return data;
            }
        });
    },
    ResetData()
    {
        this.form.order_number = "";
        this.form.delivery_date = "";
        this.form.delivery_timeslot = "";
        this.form.completion_date = "";
        this.form.created_by = "";
        this.form.items = "";
    },
    ResetEditData()
    {
        this.edit_form.order_number = "";
        this.edit_form.delivery_date = "";
        this.edit_form.delivery_timeslot = "";
        this.edit_form.completion_date = "";
        this.edit_form.created_by = "";
        this.edit_form.items = "";
        this.selected_timeslot = null;
    },
    resetFilter()
    {
      this.start = '';
      this.end = '';
      this.selected_order_status = null;
      this.form.filter_company_name = '';
      this.filter_name = null;
      this.filterTable();
    },
    resetOtherWaste(waste_type)
    { //Reset Other Waste to "" when Waste Type is not "Others"
        if (waste_type != "Others")
        {
            this.form.other_waste = null;
        }
    },
    addBI(){
      var bi = {
        image:null,
        name:'',
      }

      this.form.images.push(bi);
    },
    removeBI(index){ 
      
      this.form.images.splice(index,1);

      if (this.form.images.length == 0) {
          this.form.images.push({ image:null,name:''});
      }
    },
    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        return moment().format('YYYY-MM-DD');

      }
    },
    biInput(event,index){

      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['biInput'+index][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['biInput'+index][0].value=null;
              

          } else {
              reader.onload = (e) => {

                this.form.images[index].image = e.target.result;
                this.form.images[index].name = image.name;

            }
          }
      }
    },
    variantColor(status){
      if (status == 'Completed') {
        return 'success';
      }else if (status == 'Preparing')
      {
        return 'warning';
      }else if (status == 'New' ) 
      {
        return 'danger';
      }
      else if (status == 'Cancelled')
      {
        return 'info';
      }
    },
        breadCrumb(){
          var item = [{
            to:{name:'manage-order'},
            text: 'Manage Order',
          },{
            to:null,
            text:'Orders',
            active:true
          }];
          return item;
        },
       
    },
    mounted(){
    // this.allCompanies();
     //this.allBins();
     //this.getWasteTypeData();
     this.getOrderStatusData();
     this.getTimeslotData();
     this.allUsers();
     //this.getOrderTypeData();
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    .pac-container {
  z-index: 99999 !important; /* needs to be > z-index modal */
    }
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
